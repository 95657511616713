import React from "react";
import { useTranslation } from "react-i18next";

const Contact = () => {
    const { t } = useTranslation();

    return (
        <div className='w-full py-14 px-4 flex flex-col items-center text-center' style={{backgroundColor: "#D8ECE1"}}>
            <h1 className='text-3xl sm:text-4xl md:text-6xl font-extrabold mb-2 leading-tight tracking-tighter' style={{color: "#f84831"}}>
                {t('contact.title')}
            </h1>
            <div className="mx-auto h-1 w-24 my-1" style={{backgroundColor: "#f84831"}}></div>
            <a href={`mailto:${t('contact.email')}`} className='text-[#D8ECE1] font-bold rounded-full py-2 px-6 inline-block mt-4 transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110' style={{color: "#D8ECE1", backgroundColor: "#f84831"}}>
                <p className='text-lg sm:text-xl md:text-2xl'>
                    {t('contact.email')}
                </p>
            </a>
        </div>
    );
};

export default Contact;
