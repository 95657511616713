import { useState, useEffect } from "react";
import CircleIcon from '@mui/icons-material/Circle';

const Slider = ({ images = [], autoSlide = false, autoSlideInterval = 3000 }) => {
  const [curr, setCurr] = useState(0);

  const next = () => setCurr(curr => (curr === images.length - 1 ? 0 : curr + 1));

  useEffect(() => {
    if (!autoSlide) return;
    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  }, [autoSlide, autoSlideInterval, next]);

  return (
    <div className="overflow-hidden relative">
      <div className="flex transition-transform duration-700 ease-in-out transform" style={{ transform: `translateX(-${curr * 100}%)` }}>
        {images.map((src, index) => (
          <div key={index} className="flex-shrink-0 w-full h-full flex justify-center items-center animate-fade-in">
            <img src={src} alt={`Slide ${index + 1}`} className="w-full h-auto object-cover" />
          </div>
        ))}
      </div>

      <div className="absolute bottom-4 right-0 left-0">
        <div className="flex items-center justify-center gap-2">
          {images.map((_, i) => (
            <button key={i} className="p-1" onClick={() => setCurr(i)} aria-label={`Slide ${i + 1}`}>
              <CircleIcon fontSize="small" color={curr === i ? "inherit" : "action"} />
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Slider;