import React, { useEffect } from 'react'; 
import Principal from './components/principal/Principal';
import './translations/i18n';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './translations/i18n';
import browserLang from 'browser-lang';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const detectedLanguage = browserLang({
      languages: ['en', 'es'],
      fallback: 'en'
    });

    // Change language in i18n if detected language is different from current language
    if (detectedLanguage !== i18n.language) {
      i18n.changeLanguage(detectedLanguage);
    }
  }, [i18n]);

  return (
    <I18nextProvider i18n={i18n}>
      <div>
        <Principal />
      </div>
    </I18nextProvider>
  );
}

export default App;
