import React from "react";
import { useTranslation } from "react-i18next";
import Avocado from "../../../assets/produce/avocado.png";
import Mandarins from "../../../assets/produce/mandarin.png";
import Grapes from "../../../assets/produce/grapes.png";
import Asparagus from "../../../assets/produce/asparagus.png";
import Blueberries from "../../../assets/produce/blueberries.png";
import Mango from "../../../assets/produce/mango.png";
import Pomegranates from "../../../assets/produce/pomegranate.png";
import Apple from "../../../assets/produce/apple.png";
import Banana from "../../../assets/produce/banana.png";
import Cherry from "../../../assets/produce/cherry.png";
import Kiwi from "../../../assets/produce/kiwi.png";
import Pear from "../../../assets/produce/pear.png";

const Product = ({ imageSrc, name }) => {
    return (
        <div className="flex flex-col items-center text-center p-4 transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg hover:bg-[#ECEEE9] rounded-lg">
            <img src={imageSrc} alt={name} className="w-40 h-40 object-cover mb-4" />
            <h3 className="text-xl font-semibold mb-8" style={{ color: "#f84831" }}>{name}</h3>
        </div>
    );
};

const ProductGrid = () => {
    const { t } = useTranslation();

    const products = [
        {
            id: 1,
            name: t('inspectSet.products.avocados'),
            imageSrc: Avocado,
        },
        {
            id: 2,
            name: t('inspectSet.products.mandarins'),
            imageSrc: Mandarins, 
        },
        {
            id: 3,
            name: t('inspectSet.products.grapes'),
            imageSrc: Grapes,
        },
        {
            id: 4,
            name: t('inspectSet.products.asparagus'),
            imageSrc: Asparagus, 
        },
        {
            id: 5,
            name: t('inspectSet.products.blueberries'),
            imageSrc: Blueberries,
        },
        {
            id: 6,
            name: t('inspectSet.products.mangoes'),
            imageSrc: Mango,
        },
        {
            id: 7,
            name: t('inspectSet.products.pomegranates'),
            imageSrc: Pomegranates, 
        },
        {
            id: 8,
            name: t('inspectSet.products.apples'),
            imageSrc: Apple,
        },
        {
            id: 9,
            name: t('inspectSet.products.bananas'),
            imageSrc: Banana,
        },
        {
            id: 10,
            name: t('inspectSet.products.cherries'),
            imageSrc: Cherry,
        },
        {
            id: 11,
            name: t('inspectSet.products.kiwis'),
            imageSrc: Kiwi,
        },
        {
            id: 12,
            name: t('inspectSet.products.pears'),
            imageSrc: Pear,
        }
    ];

    return (
        <div className="min-h-screen text-center py-[7rem]" style={{ backgroundColor: "#f84831" }}>
            <h2 className="text-6xl text-center font-extrabold mb-2 leading-tight tracking-tighter" style={{ color: "#D8ECE1" }}>{t('inspectSet.heading')}</h2>
            <div className="inline-block h-1 w-24 bg-primary mb-8" style={{ backgroundColor: "#D8ECE1" }}></div>
            <div className="py-16 px-4 sm:px-6 lg:px-8 mx-auto max-w-screen-xl" style={{ backgroundColor: "#D8ECE1" }}>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                    {products.map((product) => (
                        <Product key={product.id} imageSrc={product.imageSrc} name={product.name} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductGrid;