import React from "react";
import Logo from "../../../assets/logo.jpeg";
import { SiLinkedin } from "react-icons/si";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

const Footer = () => {
  const linkedinPage = "https://www.linkedin.com/company/survey-foods";

  return (
    <div className="bg-[#2D2D2D] text-gray-300 py-[2.7rem] w-full">
      <div className="flex flex-col items-center md:flex-row justify-between px-[5%] w-full">
        <div className="flex flex-col items-center mb-6 md:mb-0 md:items-start">
          <img src={Logo} alt="Survey Foods" className="mb-4 w-16 md:w-24 min-w-[177px]" />
          <a href={linkedinPage} target="_blank" rel="noreferrer" className="text-[#f84831] hover:text-blue-500">
            <SiLinkedin size={24} />
          </a>
        </div>
        <div className="flex flex-col items-center md:items-start text-center md:text-left">
          <div className="flex items-center mb-2">
            <WhatsAppIcon className="mr-2 text-[#f84831]" />
            <span className="text-[#f84831] text-sm md:text-base">+51 999 413 975</span>
          </div>
          <div className="flex items-center">
            <EmailIcon className="mr-2 text-[#f84831]" />
            <span className="text-[#f84831] text-sm md:text-base">administracion@surveyfoods.com</span>
          </div>
          <div className="flex items-center">
            <EmailIcon className="mr-2 text-[#f84831]" />
            <span className="text-[#f84831] text-sm md:text-base">welcome@surveyfoods.com</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;