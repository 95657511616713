import React from "react";
import { ReactComponent as PeruIcon } from "../../../assets/countries/peru.svg";
import { ReactComponent as ChileIcon } from "../../../assets/countries/chile.svg";
import { ReactComponent as UKIcon } from "../../../assets/countries/uk.svg";
import { ReactComponent as MexicoIcon } from "../../../assets/countries/mexico.svg";
import { ReactComponent as USAIcon } from "../../../assets/countries/usa.svg"
import { ReactComponent as RussiaIcon } from "../../../assets/countries/russia.svg";
import { ReactComponent as ChinaIcon } from "../../../assets/countries/china.svg";
import { ReactComponent as NetherlandsIcon } from "../../../assets/countries/netherlands.svg";
import { ReactComponent as SpainIcon } from "../../../assets/countries/spain.svg";
import { ReactComponent as KoreaIcon } from "../../../assets/countries/korea.svg";
import { ReactComponent as MoroccoIcon } from "../../../assets/countries/morocco.svg";
import { ReactComponent as ItalyIcon } from "../../../assets/countries/italy.svg";
import { useTranslation } from "react-i18next";


// Color constants
const PRIMARY_COLOR = "#f84831";
const BACKGROUND_COLOR = "#D8ECE1";
const HOVER_BACKGROUND_COLOR = "hover:bg-[#ECEEE9]"; // Using Tailwind class for hover effect

const CountryBlock = ({ country }) => {
  const Icon = country.icon;
  return (
    <div className={`flex flex-col items-center p-4 rounded-lg ${HOVER_BACKGROUND_COLOR} transition-shadow duration-300 ease-in-out`}>
      <Icon style={{ fill: PRIMARY_COLOR, width: '96px', height: '96px' }} />
      <p className="mt-3 text-lg font-semibold" style={{ color: PRIMARY_COLOR }}>{country.name}</p>
    </div>
  );
};

const Locations = () => {
  const { t } = useTranslation();

  const countries = [
    { id: 1, name: t('locations.peruName'), icon: PeruIcon, location: "Peru" },
    { id: 2, name: t('locations.chileName'), icon: ChileIcon, location: "Chile" },
    { id: 3, name: t('locations.mexicoName'), icon: MexicoIcon, location: "Mexico" },
    { id: 4, name: t('locations.ukName'), icon: UKIcon, location: "UK" },
    { id: 5, name: t('locations.usaName'), icon: USAIcon, location: "USA" },
    { id: 6, name: t('locations.russiaName'), icon: RussiaIcon, location: "Russia" },
    { id: 7, name: t('locations.netherlandsName'), icon: NetherlandsIcon, location: "Netherlands" },
    { id: 8, name: t('locations.italyName'), icon: ItalyIcon, location: "Italy"},
    { id: 8, name: t('locations.spainName'), icon: SpainIcon, location: "Spain" },
    { id: 9, name: t('locations.moroccoName'), icon: MoroccoIcon, location: "Morocco" },
    { id: 10, name: t('locations.chinaName'), icon: ChinaIcon, location: "China" },
    { id: 11, name: t('locations.koreaName'), icon: KoreaIcon, location: "Korea" },
  ];

  return (
    <div className="py-[7rem] px-4" style={{ backgroundColor: "#D8ECE1", color: "#f84831" }}>
      <div className="text-center">
        <h1 className="text-6xl font-extrabold mb-2">{t('locations.title')}</h1>
        <div className="inline-block h-1 w-24 bg-primary mb-4" style={{ backgroundColor: "#f84831" }}></div>
        <p className="text-xl font-semibold mb-8 max-w-xl mx-auto">{t('locations.description')}</p>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {countries.map((country) => (
          <CountryBlock key={country.id} country={country} />
        ))}
      </div>
    </div>
  );
};

export default Locations;
